import React, {Component} from "react";
import Box from "@mui/material/Box";
import {
    AppBar, Backdrop, CircularProgress, IconButton,
    ListItem, ListItemText, Toolbar
} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import plyr from 'plyr'
import 'plyr/dist/plyr.css'
import $ from "jquery";
let loadingStateInterval;
export default class Player extends Component {

    componentDidMount() {
        if (window.main.android()) {
            window.Android.setNavAndStatus("#000000");
        } else {
            if (navigator.userAgent.includes("OtakuTime")) {
                let item = JSON.parse("{}");
                item.type = "changeBottom";
                item.color = "#000000";
                item.isDark = "true";
                window.webkit.messageHandlers.m.postMessage(item);
                let item2 = JSON.parse("{}");
                item2.type = "changeTop";
                item2.color = "#000000";
                item2.isDark = "true";
                window.webkit.messageHandlers.m.postMessage(item2);
            } else {
                if (window.main.ios()) {
                    let item = JSON.parse("{}");
                    item["R"] = 0;
                    item["G"] = 0;
                    item["B"] = 0;
                    item["dark"] = 1;
                    item["type"] = "theme";
                    window.webkit.messageHandlers.callback.postMessage(JSON.stringify(item));
                } else {
                    if (window.navigator.userAgent.includes("SAnimeWeb")) {
                        let item = JSON.parse("{}");
                        item.type = "changeTheme";
                        item.color = "#000000";
                        window.chrome.webview.postMessage(JSON.stringify(item));
                    }
                }
            }
        }
        if (window.main.isOtakuTime()) {
            this.player = new plyr('.js-plyr',
                {
                    "controls":['play-large', 'play', 'progress', 'current-time', 'mute', 'settings'],
                    fullscreen: {enabled: false},
                    iconUrl: "https://ios.sanime.net/plyr.svg"
                }
            );
        } else {
            if (window.main.ios()) {
                this.player = new plyr('.js-plyr', {
                    "controls":['play-large', 'play', 'progress', 'current-time', 'mute', 'settings'],
                    fullscreen: {enabled: false},
                    iconUrl: "https://ios.sanime.net/plyr.svg"
                });
            } else {
                if (window.navigator.userAgent.includes("SAnimeWeb")) {
                    this.player = new plyr('.js-plyr', {
                        "controls":['play-large', 'play', 'progress', 'current-time', 'mute', 'settings', 'fullscreen'],
                        fullscreen: {enabled: true},
                        iconUrl: "https://ios.sanime.net/plyr.svg"
                    });
                } else {
                    this.player = new plyr('.js-plyr', {
                        "controls":['play-large', 'play', 'progress', 'current-time', 'mute', 'settings', 'fullscreen'],
                        iconUrl: "https://ios.sanime.net/plyr.svg"
                    });
                }
            }
        }
        this.player.autoplay = true;
        this.player.source = {
            type: 'video',
            title: window.localStorage.getItem("videoName"),
            sources: [
                {
                    src: window.localStorage.getItem("sdUrl"),
                    type: 'video/mp4',
                    size: 360,
                },
                {
                    src: window.localStorage.getItem("hdUrl"),
                    type: 'video/mp4',
                    size: 720,
                },
            ],
        };
        if (!window.main.android()) {
            if (!window.navigator.userAgent.includes("SAnimeWeb")) {
                setTimeout(() => {
                    var controls = document.getElementsByClassName("plyr__controls")[0];

                    // Remove the old fullscreen button
                    var oldFullscreenButton = document.querySelector('[data-plyr="fullscreen"]');
                    if (oldFullscreenButton) {
                        oldFullscreenButton.remove();
                    }

                    // Create new fullscreen button
                    const button = document.createElement('div');
                    button.className = 'plyr__controls__item plyr__control';
                    button.type = 'button';
                    button.setAttribute('aria-pressed', 'false');

                    // Create the SVG elements for pressed and not pressed states
                    const svgPressed = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                    svgPressed.setAttribute('class', 'icon--pressed');
                    svgPressed.setAttribute('aria-hidden', 'true');
                    svgPressed.setAttribute('focusable', 'false');
                    const usePressed = document.createElementNS('http://www.w3.org/2000/svg', 'use');
                    usePressed.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#plyr-exit-fullscreen');
                    svgPressed.appendChild(usePressed);

                    const svgNotPressed = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                    svgNotPressed.setAttribute('class', 'icon--not-pressed');
                    svgNotPressed.setAttribute('aria-hidden', 'true');
                    svgNotPressed.setAttribute('focusable', 'false');
                    const useNotPressed = document.createElementNS('http://www.w3.org/2000/svg', 'use');
                    useNotPressed.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#plyr-enter-fullscreen');
                    svgNotPressed.appendChild(useNotPressed);

                    // Create the span elements for labels
                    const spanPressed = document.createElement('span');
                    spanPressed.className = 'label--pressed plyr__sr-only';
                    spanPressed.textContent = 'Exit fullscreen';

                    const spanNotPressed = document.createElement('span');
                    spanNotPressed.className = 'label--not-pressed plyr__sr-only';
                    spanNotPressed.textContent = 'Enter fullscreen';

                    // Append elements to the button
                    button.appendChild(svgPressed);
                    button.appendChild(svgNotPressed);
                    button.appendChild(spanPressed);
                    button.appendChild(spanNotPressed);

                    // Function to toggle fullscreen
                    function toggleFullscreen() {
                        let isFullscreen = document.fullscreenElement != null;
                        let isFull = document.getElementById("headers").style.display;
                        if (isFull === "none") {
                            isFullscreen = true;
                        }
                        if (!isFullscreen) {
                            if (document.documentElement.requestFullscreen) {
                                document.documentElement.requestFullscreen();
                            }
                            button.setAttribute('aria-pressed', 'true');
                            document.getElementById("headers").style.display = "none";
                            document.getElementById("videoId").style.height = "97%";
                            document.getElementsByClassName("fullScreenDiv")[0].style.overflow = "auto";
                        } else {
                            if (document.exitFullscreen) {
                                document.exitFullscreen();
                            }
                            button.setAttribute('aria-pressed', 'false');
                            document.getElementById("headers").style.display = "block";
                            document.getElementById("videoId").style.height = "92%";
                            document.getElementsByClassName("fullScreenDiv")[0].style.overflow = "auto";
                        }
                    }

                    // Add event listener to the button
                    button.addEventListener('click', toggleFullscreen);

                    // Append the new fullscreen button based on device type
                    if (window.main.android()) {
                        // controls.appendChild(button); // Uncomment if needed for Android
                    } else {
                        if (window.main.ios()) {
                            controls.appendChild(button);
                        } else {
                            if (window.main.isOtakuTime()) {
                                controls.appendChild(button);
                            }
                        }
                    }
                }, 500);
            }
        }
        if (window.main.android()) {
            loadingStateInterval = setInterval(() => {
                let loadingState = $(".plyr--loading");
                if (loadingState.length !== 0) {
                    window.playerApp.setState({
                        ldn: true
                    });
                } else {
                    window.playerApp.setState({
                        ldn: false
                    });
                }
            },100);
        }
        if (window.navigator.userAgent.includes("SAnimeWeb")) {
            let item = JSON.parse("{}");
            item.type = "changeTitle";
            item.title = window.localStorage.getItem("videoName");
            window.chrome.webview.postMessage(JSON.stringify(item));

            document.addEventListener('fullscreenchange', () => {
                if (document.fullscreenElement) {
                    if (window.navigator.userAgent.includes("SAnimeWeb")) {
                        let item = JSON.parse("{}");
                        item.type = "fullscreen";
                        window.chrome.webview.postMessage(JSON.stringify(item));
                    }
                } else {
                    if (window.navigator.userAgent.includes("SAnimeWeb")) {
                        let item = JSON.parse("{}");
                        item.type = "fullscreen";
                        window.chrome.webview.postMessage(JSON.stringify(item));
                    }
                }
            });
        }
    }

    componentWillUnmount() {
        clearInterval(loadingStateInterval);
        this.player.destroy();
        if (window.main.android()) {
            if (window.main.checkTheme()) {
                window.Android.setNav("#262e39");
                window.Android.setStatus("#182128");
            } else {
                window.Android.setNav("#ffffff");
                window.Android.setStatus("#efeeee");
            }
        } else {
            if (window.main.ios()) {
                let item = JSON.parse("{}");
                if (window.main.checkTheme()) {
                    item["R"] = 24;
                    item["G"] = 33;
                    item["B"] = 40;
                    item["dark"] = 1;
                    item["type"] = "theme";
                    window.webkit.messageHandlers.callback.postMessage(JSON.stringify(item));
                } else {
                    item["R"] = 239;
                    item["G"] = 238;
                    item["B"] = 238;
                    item["dark"] = 0;
                    item["type"] = "theme";
                    window.webkit.messageHandlers.callback.postMessage(JSON.stringify(item));
                }
            } else {
                if (window.navigator.userAgent.includes("SAnimeWeb")) {
                    if (window.main.checkTheme()) {
                        let item = JSON.parse("{}");
                        item.type = "changeTheme";
                        item.color = "#182128";
                        window.chrome.webview.postMessage(JSON.stringify(item));
                    } else {
                        let item = JSON.parse("{}");
                        item.type = "changeTheme";
                        item.color = "#efeeee";
                        window.chrome.webview.postMessage(JSON.stringify(item));
                    }
                }
            }
        }
        window.playerApp.setState({
            ldn: false
        });
        if (!window.main.checkLoggedAndPremium()) {
            if (window.main.android()) {
                window.main.openFull();
            } else {
                setTimeout(function () {
                    if (window.main.ios()) {
                        window.webkit.messageHandlers.callback.postMessage(JSON.stringify({
                            "type": "shad"
                        }));
                    }
                },3000);
            }
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingPlayer: true,
            ldn: false
        };
        window.playerApp = this;
    }

    checkTheme = () => {
        if (localStorage.getItem("theme") !== null) {
            return localStorage.getItem("theme").includes("true");
        } else {
            return true
        }
    }

    render() {
        return <Box style={{background: "#000000",height: "100%"}}>
            <Box id={"headers"} style={{background: "#000000"}}>
                <AppBar position="static" elevation={0} style={{background: "#000000"}}>
                    <Toolbar variant="dense" sx={{paddingLeft: "9px",paddingRight:"9px"}}>
                        <IconButton
                            onClick={() => {
                                // eslint-disable-next-line no-restricted-globals
                                history.back();
                            }}
                            size="medium"
                            edge="start"
                            color="inherit"
                            aria-label="back"
                            sx={{ marginRight: "0px" }}
                        >
                            <ArrowBack />
                        </IconButton>
                        <ListItem sx={{padding: "0px"}}>
                            <ListItemText primary={window.localStorage.getItem("videoName")} secondary={""} />
                        </ListItem>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box id={"videoId"} style={{background: "#000000", flexGrow: 1,alignItems: "center",textAlign: "center",height: "92%"}}>
                <video autoPlay={true} className='js-plyr plyr' style={{height: "100%",width: "100%"}} controls/>
            </Box>
            <Backdrop
                sx={{ position: "fixed",height: "100%",color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.state.ldn}
            >
                <CircularProgress sx={{color: "#2196f3"}} />
            </Backdrop>
        </Box>
    }
}
