export class IndexedDBStorage {
    constructor(dbName) {
        this.dbName = dbName;
        this.dbPromise = this.initDB();
    }

    initDB() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, 1);

            request.onerror = (event) => {
                console.error('Failed to open IndexedDB:', event.target.errorCode);
                reject(event.target.errorCode);
            };

            request.onsuccess = (event) => {
                resolve(event.target.result);
            };

            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                const store = db.createObjectStore('data', { keyPath: 'key' });
                store.createIndex('key', 'key', { unique: true });
            };
        });
    }

    setItem(key, value) {
        return this.dbPromise.then((db) => {
            const transaction = db.transaction(['data'], 'readwrite');
            const store = transaction.objectStore('data');
            return new Promise((resolve, reject) => {
                const request = store.put({ key, value });
                request.onsuccess = () => resolve();
                request.onerror = (event) => reject(event.target.error);
            });
        });
    }

    getItem(key) {
        return this.dbPromise.then((db) => {
            const transaction = db.transaction(['data'], 'readonly');
            const store = transaction.objectStore('data');
            return new Promise((resolve, reject) => {
                const request = store.get(key);
                request.onsuccess = (event) => {
                    resolve(event.target.result ? event.target.result.value : null);
                };
                request.onerror = (event) => reject(event.target.error);
            });
        });
    }

    removeItem(key) {
        return this.dbPromise.then((db) => {
            const transaction = db.transaction(['data'], 'readwrite');
            const store = transaction.objectStore('data');
            return new Promise((resolve, reject) => {
                const request = store.delete(key);
                request.onsuccess = () => resolve();
                request.onerror = (event) => reject(event.target.error);
            });
        });
    }

    getAllKeys() {
        return this.dbPromise.then((db) => {
            const transaction = db.transaction(['data'], 'readonly');
            const store = transaction.objectStore('data');
            return new Promise((resolve, reject) => {
                const request = store.getAllKeys();
                request.onsuccess = (event) => {
                    resolve(event.target.result);
                };
                request.onerror = (event) => reject(event.target.error);
            });
        });
    }
}