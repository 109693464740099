import React, {Component} from "react";
import Box from "@mui/material/Box";
import {Tab, Tabs} from "@mui/material";
import {a11yProps, CustomTabPanel} from "../functions";
import Grow from "@mui/material/Grow";
import FavoriteFragment from "./fav/favorite";
import Watched from "./fav/watched";

export default class Favorite extends Component {

    componentDidMount() {}

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tabValue:0
        };
    }
    checkTheme = () => {
        if (localStorage.getItem("theme") !== null) {
            return localStorage.getItem("theme").includes("true");
        } else {
            return true
        }
    }
    handleChange = (event, newValue) => {
        this.setState({
            tabValue: newValue
        });
    };
    render() {
        return <Box style={{marginTop: '0px',marginBottom: '7px'}} height={'100%'}>
            <Box sx={{ margin:"3px", backgroundColor: "background.paper"}}>
                <Tabs value={this.state.tabValue} onChange={this.handleChange} aria-label="infoPage" centered TabIndicatorProps={{
                    title: "indicator",
                    hidden: true
                }}>
                    <Tab style={{fontFamily: "Changa",borderRadius:"100px",margin: "5px 0px 5px 0px"}} label="المفضلة" className={window.main.state.mode === "dark" ? "tabRoot myTabD" : "tabRoot myTabL"} {...a11yProps(0)} />
                    <Tab style={{fontFamily: "Changa",borderRadius:"100px",margin: "5px 0px 5px 0px"}} label="المشاهدات" className={window.main.state.mode === "dark" ? "tabRoot myTabD" : "tabRoot myTabL"} {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={this.state.tabValue} index={0}>
                <Grow in={this.state.tabValue === 0}>
                    <div>
                        <FavoriteFragment/>
                    </div>
                </Grow>
            </CustomTabPanel>
            <CustomTabPanel value={this.state.tabValue} index={1}>
                <Grow in={this.state.tabValue === 1}>
                    <div>
                        <div>
                            <Watched/>
                        </div>
                    </div>
                </Grow>
            </CustomTabPanel>
        </Box>
    }
}
