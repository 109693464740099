import React, {Component} from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {NewReleasesSharp} from "@mui/icons-material";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import {Pagination} from "@mui/material";
import {CenterLoading} from "../functions";

export default class AnimeList extends Component {

    componentDidMount() {}
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            error: false,
            animePage: 1
        };
        this.prepare();
    }
    checkTheme = () => {
        if (localStorage.getItem("theme") !== null) {
            return localStorage.getItem("theme").includes("true");
        } else {
            return true
        }
    }

    accountData = () => {
        if (this.checkLogged()) {
            return JSON.parse(localStorage.getItem("loggedData"));
        }
    }

    checkLogged = () => {
        return localStorage.getItem("logged") !== null;
    }

    prepare = () => {
        let url = 'https://app.sanime.net/function/h10.php?page=animelist';
        if (this.checkLogged()) {
            url = 'https://app.sanime.net/function/h10.php?page=animelist&email='+this.accountData().email;
        }
        fetch(url).then((value) => {
            value.text().then((value) => {
                let JS = JSON.parse(value);
                this.setState({
                    data: JS,
                    loading: false
                });
            });
        }).catch((error) => {
            this.setState({
                loading: false,
                error: true
            });
        });
    }
    restartPage = () => {
        this.setState({
            loading: true,
            error: false
        });
        setTimeout(()=> {
            this.prepare();
        },500);
    }
    animePageChanged = (event, value) => {
        this.setState({animePage: value});
    }
    render() {
        if (this.state.loading) {
            return ( <CenterLoading/> );
        } else if (this.state.error) {
            return <Box>
                <Container style={{placeContent: 'center',textAlign: 'center',marginTop: '20px'}}>
                    <Card>
                        <NewReleasesSharp style={{margin: "10px"}}/>
                        <CardContent style={this.checkTheme() ? {color: '#ffffff'} : {color: '#000000'}}>
                            توجد مشكلة في الشبكة حاول مجددا
                        </CardContent>
                        <Button onClick={this.restartPage} style={{margin: "8px", backgroundColor: '#03a9f4',color: 'white'}}>
                            أعادة المحاولة
                        </Button>
                    </Card>
                </Container>
            </Box>
        } else {
            return <Box style={{marginTop: '7px'}} height={'100%'}>
                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={1.4}>
                            {this.state.data[this.state.animePage - 1].map(r =>
                                <Grid key={r.id} item>
                                    <Card sx={{ width: window.main.if360() ? 105 : 120 }} >
                                        <CardActionArea onClick={window.main.goLink.bind(this,'/info?id='+r.id)}>
                                            <CardMedia
                                                component="img"
                                                height="185"
                                                image={r.image}
                                                alt={r.name}
                                            />
                                            <Divider sx={{height: 1.2}} color={'#00b2ff'}/>
                                            <Stack style={{paddingRight: '4px',paddingLeft: '4px',paddingBottom: '3px',paddingTop: '1px'}} spacing={0}>
                                                <Typography style={{textOverflow: "ellipsis",overflow: "hidden",whiteSpace: "nowrap",fontSize: '14px'}}>{r.name}</Typography>
                                                <Typography style={{textAlign: 'right',fontSize: '14px'}}>{r.status}</Typography>
                                            </Stack>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Pagination className={"centerPage"} style={{margin: '4px'}} siblingCount={0} size="large" color={"primary"} page={this.state.animePage} onChange={this.animePageChanged} count={this.state.data.length} variant="outlined" shape="rounded" />
                <div style={{height: 50}}/>
            </Box>;
        }
    }
}
