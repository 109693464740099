import HomeIcons from '@mui/icons-material/HomeOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
export let HomeIcon = HomeIcons;
export let ChatIcon = ChatBubbleOutlineOutlinedIcon;
export let FavIcon = BookmarksOutlinedIcon;
export let NewsIcon = NewspaperOutlinedIcon;
export let SettingIcon = SettingsIcon;
export let SearchsIcon = SearchIcon;
export let LeftIcon = KeyboardArrowLeft;
export let RightIcon = KeyboardArrowRight;