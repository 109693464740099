import React, {Component} from "react";
import Box from "@mui/material/Box";
import {
    Avatar, CardContent,
    CardHeader, CircularProgress,
    Divider,
    Fade,
    IconButton,
    InputBase,
    ListItem,
    Paper,
    Tab,
    Tabs,
    Tooltip,
} from "@mui/material";
import {a11yProps, CenterEmpty, CustomTabPanel} from "../functions";
import Grow from "@mui/material/Grow";
import Community from "../pages/community";
import {CheckCircleOutline, DeleteOutline, SendOutlined} from "@mui/icons-material";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import $ from "jquery";

// eslint-disable-next-line no-unused-vars
let interval = null;
export default class CommunityPage extends Component {

    componentDidMount() {}

    constructor(props) {
        super(props);
        this.state = {
            showed: 0,
            loading: false,
            tabValue:0,
            message: "",
            messages: [],
            messageLoading: true
        };
        window.chats = this;
    }

    showFull = () => {
        let showed = this.state.showed;
        if (showed >= 5) {
            if (!window.main.checkLoggedAndPremium()) {
                if (window.main.ios()) {
                    window.webkit.messageHandlers.callback.postMessage(JSON.stringify({
                        "type": "shad"
                    }));
                }
            }
            this.setState({
                showed: 0
            });
        } else {
            showed++;
            this.setState({
                showed: showed
            });
        }
    }

    isMe = (id) => {
        if (window.main.checkLogged()) {
            let userData = localStorage.getItem("loggedData");
            userData = JSON.parse(userData);
            return userData["id"] === parseInt(id);
        }
        return false;
    }

    prepareChat = () => {
        interval = setInterval(function() {
            fetch('https://app.sanime.net/secure/chat/list.php').then((response) => {
                response.text().then((va) => {
                    const vas = JSON.parse(va);
                    window.chats.setState({
                        messages: vas
                    });
                    try {
                        if (window.chats.state.messages[window.chats.state.messages.length - 1].id !== vas[vas.length - 1].id) {
                            $("main").animate({ scrollTop: 10000 }, 500);
                        }
                    } catch (e) {
                        console.log("");
                    }
                });
            }).catch((err) => {});
        }, 2000);
    }
    checkTheme = () => {
        if (localStorage.getItem("theme") !== null) {
            return localStorage.getItem("theme").includes("true");
        } else {
            return true
        }
    }
    handleChange = (event, newValue) => {
        this.setState({
            tabValue: newValue
        });
        if (newValue === 1) {
            window.home.setState({
               post: false
            });
            this.setState({
                messageLoading: true,
                messages: []
            });
            fetch('https://app.sanime.net/secure/chat/list.php').then((response) => {
                response.text().then((va) => {
                    const vas = JSON.parse(va);
                    this.setState({
                        messageLoading: false,
                        messages: vas
                    });
                    $("main").animate({ scrollTop: 10000 }, 500);
                });
            }).catch((err) => {
                this.setState({
                   tabValue: 0
                });
                window.main.showMessage("توجد مشكلة حاول مجددا","تنبيه","حسنا","",true);
            });
            this.prepareChat();
            setTimeout(() => {
                document.getElementById("chatInput").addEventListener("keypress", function(event) {
                    if (event.key === "Enter") {
                        event.preventDefault();
                        window.chats.sendMessage();
                    }
                });
            },600);
        } else {
            const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
            for (let i = 1; i < interval_id; i++) {
                window.clearInterval(i);
            }
            window.home.setState({
                post: true
            });
            $("main").animate({ scrollTop: 0 }, 500);
        }
    };
    sendMessage = () => {
        if (localStorage.getItem("logged") !== null) {
            let data = JSON.parse(localStorage.getItem("loggedData"));
            window.main.setState({
                loading: true
            });

            // Create a new FormData object
            let formData = new FormData();
            formData.append('email', data.email);
            formData.append('password', data.password);
            formData.append('m', btoa(unescape(encodeURIComponent(this.state.message))));

            fetch('https://app.sanime.net/secure/chat/send.php', {
                method: 'POST',
                body: formData
            }).then((response) => {
                fetch('https://app.sanime.net/secure/chat/list.php').then((response) => {
                    response.text().then((va) => {
                        window.main.setState({
                            loading: false
                        });
                        const vas = JSON.parse(va);
                        this.setState({
                            messageLoading: false,
                            messages: vas
                        });
                        $("main").animate({ scrollTop: 10000 }, 500);
                        this.showFull();
                    });
                }).catch((err) => {
                    window.main.setState({
                        loading: false
                    });
                    window.main.showMessage("توجد مشكلة حاول مجددا", "تنبيه", "حسنا", "", true);
                });
                this.setState({
                    message: "",
                });
                response.text().then((va) => {
                    window.main.showMessage(va, "تنبيه", "حسنا", "", true);
                });
            }).catch((err) => {
                window.main.setState({
                    loading: false
                });
                window.main.showMessage("توجد مشكلة حاول مجددا", "تنبيه", "حسنا", "", true);
            });
        } else {
            window.main.showMessage("يجب عليك تسجيل الدخول للنشر", "تنبيه", "حسنا", "", true);
        }
    }
    deleteMessage = (id) => {
        if (localStorage.getItem("logged") !== null) {
            let data = JSON.parse(localStorage.getItem("loggedData"));
            window.main.switchLoading();
            let url = "https://app.sanime.net/secure/chat/delete.php";
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: 'id=' + encodeURIComponent(id) + "&email=" + encodeURIComponent(data.email) + "&password="+ encodeURIComponent(data.password),
            }).then((value) => {
                window.main.switchLoading();
                value.text().then((text) => {
                    window.main.showMessage(text,"","حسنا","",false);
                });
            }).catch((err) => {
                window.main.switchLoading();
            });
        } else {
            window.main.showMessage("يجب عليك تسجيل الدخول للحذف","تنبيه","حسنا","",true);
        }
    }

    isAdmin = () => {
        if (localStorage.getItem("logged") !== null) {
            let data = JSON.parse(localStorage.getItem("loggedData"));
            return !!data.isAdmin.includes("true");
        } else {
            return false;
        }
    }
    render() {
        return <Box style={{marginTop: '0px',marginBottom: '7px'}} height={'100%'}>
            <Box sx={{ margin:"3px", backgroundColor: "background.paper"}}>
                <Tabs value={this.state.tabValue} onChange={this.handleChange} aria-label="infoPage" centered TabIndicatorProps={{
                    title: "indicator",
                    hidden: true
                }}>
                    <Tab style={{fontFamily: "Changa",borderRadius:"100px",margin: "5px 0px 5px 0px"}} label="المجتمع" className={window.main.state.mode === "dark" ? "tabRoot myTabD" : "tabRoot myTabL"} {...a11yProps(0)} />
                    <Tab style={{fontFamily: "Changa",borderRadius:"100px",margin: "5px 0px 5px 0px"}} label="الدردشة" className={window.main.state.mode === "dark" ? "tabRoot myTabD" : "tabRoot myTabL"} {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={this.state.tabValue} index={0}>
                <Grow in={this.state.tabValue === 0}>
                    <div>
                        <Community/>
                    </div>
                </Grow>
            </CustomTabPanel>
            <CustomTabPanel value={this.state.tabValue} index={1}>
                <Grow in={this.state.tabValue === 1}>
                    <div>
                        {
                            this.state.messageLoading ? <Container style={{placeContent: 'center',textAlign: 'center',height: '100%'}}>
                                <div style={{height: '350px'}} />
                                <CircularProgress style={{color: '#00b2ff'}}/>
                                <div/>
                            </Container> : <Box>
                                {this.state.messages.length === 0 ? <CenterEmpty/> : <Box/>}
                                <Container sx={{paddingLeft: "0px",paddingRight: "0px"}}>
                                    <List style={{paddingTop: "0px"}}>
                                        {
                                            this.state.messages.map( (item, index) =>
                                                    <ListItem style={{paddingLeft: "7px", paddingRight: "7px"}} key={item.id}>
                                                        <Card sx={item.admin.toString().includes("true") ? { width: "100%",borderRadius: "8px",background: "transparent",borderColor: "gold !important" } : { width: "100%",borderRadius: "8px",background: "transparent" }} className={this.checkTheme() ? "linedShadowD" : "linedShadowL"}>
                                                            <CardHeader
                                                                avatar={
                                                                    item.admin.toString().includes("true") ? <Tooltip title="الأدارة">
                                                                        <Avatar aria-label="recipe" src={item.image}/>
                                                                    </Tooltip> : <Avatar aria-label="recipe" src={item.image}/>
                                                                }
                                                                title={item.admin.toString().includes("false") ? <Box>
                                                                    <span style={this.checkTheme() ? {color: "white",display: "inline-flex"} : {color: "black",display: "inline-flex"}}>
                                                                        {item.name}
                                                                        <div style={{marginLeft: "4px"}}>
                                                                            {
                                                                                item.admin.toString().includes("true") ? <CheckCircleOutline style={{fontSize: "large",color: "#00b2ff"}}/> : <div/>
                                                                            }
                                                                        </div>
                                                                    </span>
                                                                </Box> : <Box>
                                                                    <span style={{color: "gold", textShadow: '0px 0px 5px #ffeb3b',display: "inline-flex"}}>
                                                                        {item.name}
                                                                        <div style={{marginLeft: "4px"}}>
                                                                            {
                                                                                item.admin.toString().includes("true") ? <CheckCircleOutline style={{fontSize: "large",color: "#00b2ff"}}/> : <div/>
                                                                            }
                                                                        </div>
                                                                    </span>
                                                                </Box>}
                                                                subheader={<Typography style={{fontSize: "14px",opacity: "0.8"}}>{item.time}</Typography>}
                                                                action={
                                                                    this.isAdmin() ? <IconButton onClick={this.deleteMessage.bind(this,item.id)}>
                                                                        <DeleteOutline/>
                                                                    </IconButton> : this.isMe(item.userId) ? <IconButton onClick={this.deleteMessage.bind(this,item.id)}>
                                                                        <DeleteOutline/>
                                                                    </IconButton> : <div/>
                                                                }
                                                            />
                                                            <CardContent style={{textAlign: "unset",paddingTop: "1px",paddingBottom: "3px"}} dir="auto">
                                                                <Typography style={{width: "100%",textAlign: "initial",whiteSpace: "pre-line"}} dir="auto" aria-multiline={true}>{item.message}</Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </ListItem>
                                            )
                                        }
                                    </List>
                                    <Box style={{height:"50px"}}/>
                                </Container>
                            </Box>
                        }
                    </div>
                </Grow>
            </CustomTabPanel>
            <Fade
                in={this.state.tabValue === 1}
                timeout={300}
                unmountOnExit
            >
                <Box className={"fabChat"}>
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center',borderRadius: "100px",width: "95%" }}
                    >
                        <InputBase
                            id={"chatInput"}
                            sx={{ ml: 1, flex: 1,borderRadius: "100px",direction:"rtl" }}
                            placeholder="اكتب رسالتك"
                            onChange={(value) => {
                                this.setState({
                                    message: value.target.value
                                })
                            }}
                            onClick={() => {
                                setTimeout(() => {
                                    $("html").animate({ scrollTop: -10000 }, 500);
                                    $("body").animate({ scrollTop: -10000 }, 500);
                                },200);
                            }}
                            value={this.state.message}
                            inputProps={{ 'aria-label': 'type' }}
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={this.sendMessage}>
                            <SendOutlined />
                        </IconButton>
                    </Paper>
                </Box>
            </Fade>
        </Box>
    }
}
