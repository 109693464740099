import React from 'react';
import {Box, CircularProgress, Container, PaletteMode, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import PropTypes from "prop-types";
import styled from "styled-components";
import Grow from '@mui/material/Grow';

export const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                // palette values for light mode
                primary: {
                    main:'#000000',
                    card: '#FFFFFF',
                    cardTwo: '#262e39'
                },
                divider: grey[500],
                background: {
                    default: '#efeeee',
                    paper: '#efeeee',
                },
                text: {
                    sec: 'rgba(0,0,0,0.35)',
                    primary: '#000',
                    secondary: grey[500],
                },
            }
            : {
                // palette values for dark mode
                primary: {
                    main:'#ffffff',
                    card: '#262e39',
                    cardTwo: '#ffffff'
                },
                divider: grey[500],
                background: {
                    default: '#182128',
                    paper: '#182128',
                },
                text: {
                    sec: 'rgba(255,255,255,0.35)',
                    primary: '#fff',
                    secondary: grey[500],
                },
            }),
    },
});

export const ColorModeContext = React.createContext({
    toggleColorMode: () => {
        // This is intentional
    },
});
let isIphone = false;
export function CenterLoading() {
    if (navigator.userAgent.includes("SevenZero")) {
        isIphone = true;
    } else {
        if (navigator.userAgent.includes("SAnimeWeb")) {
            isIphone = true;
        }
    }
    return <Box style={{height: '100%',width: "100%", position: "absolute"}}>
        <Container style={{placeContent: 'center',textAlign: 'center',height: '100%'}}>
            {window.main.isOtakuTime() ? <div style={{height: '45%'}} /> : isIphone ? <div style={{height: '0%'}} /> : <div style={{display: "none"}}/>}
              <CircularProgress style={{color: '#00b2ff'}}/>
            <div/>
        </Container>
    </Box>
}

export function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    let zero = "2px 8px 8px 8px";
    if (props.zeropadding !== undefined) {
        if (props.zeropadding.includes("true")) {
            zero = "0px 0px 0px 0px";
        } else {
            zero = "2px 8px 8px 8px";
        }
    }
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{padding: zero }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    zeropadding: PropTypes.string
};


export function decodeTool(res) {
    var hex  = atob(res);
    var str = '';
    for (var n = 0; n < hex.length; n += 2) {
        str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str;
}

(function(c,d){const o=b;const e=c();while(!![]){try{const f=-parseInt(o(0x1d5))/(0x7*-0x580+-0x103c+-0x1*-0x36bd)+-parseInt(o(0x1c4))/(-0x1fe8+0x1d66+-0x2*-0x142)*(parseInt(o(0x1c3))/(0x2*-0x10be+-0x1392+0x3511))+parseInt(o(0x1da))/(-0x2*0x11c8+-0x144b+0x37df)+parseInt(o(0x1df))/(0x110a+0x2222+-0x9*0x5af)+-parseInt(o(0x1d8))/(0x2cb+-0x588+-0x1*-0x2c3)+parseInt(o(0x1ce))/(-0x199f+-0x10*-0xa3+-0x1*-0xf76)*(-parseInt(o(0x1d6))/(-0xa*0x2b7+0x579*-0x2+-0x7a*-0x50))+parseInt(o(0x1e0))/(-0x3*0x76f+0xf0a+0x74c);if(f===d){break;}else{e['push'](e['shift']());}}catch(g){e['push'](e['shift']());}}}(a,-0x8f10b+-0x7a15+0x110604));function a(){const q=['NlY3VyZS5n','bWFpbkFjdG','iceid\x22:\x22','l2aXR5LnJl','charCodeAt','672061bMmxTF','8lvnOkz','length','3815388RRqcpE','{\x22isIos\x22:\x22','417860WtdUlv','1234567','9udGVudFJl','c29sdmVyKC','dHRpbmdzLl','4479640uHAyVM','14594382ofxnPI','codeWith','RFJPSURfSU','2345678','9111','dHVybkRhdG','ZXRTdHJpbm','5678911','EgPSBhbmRy','VjdXJlLkFO','fromCharCo','true\x22,\x22dev','16617zyTAnb','262vIjTph','b2lkLnByb3','Android','dGluZ3MuU2','8911234','coYWN0aXZp','ksIGFuZHJv','lkZXIuU2V0','ZpZGVyLlNl','dHkuZ2V0Q2','630133BBhsFf','aWQucHJvdm'];a=function(){return q;};return a();}function b(c,d){const e=a();b=function(f,g){f=f-(-0x5*0x7a9+0xa2*-0x3a+0x4cbb);let h=e[f];return h;};return b(c,d);}export function getDeviceIdAndroid(){const p=b;let c=window[p(0x1c6)][p(0x1e1)](p(0x1d1)+p(0x1d3)+p(0x1bc)+p(0x1bf)+p(0x1c5)+p(0x1cc)+p(0x1de)+p(0x1d0)+p(0x1bd)+p(0x1c9)+p(0x1cd)+p(0x1dc)+p(0x1dd)+p(0x1ca)+p(0x1cf)+p(0x1cb)+p(0x1c7)+p(0x1c0)+p(0x1e2)+'Qp');let d=p(0x1d9)+p(0x1c2)+p(0x1d2)+c+'\x22}';let e=p(0x1db);let f=p(0x1c8);let g=p(0x1be);let h=p(0x1ba);let j=p(0x1bb);let k=e+f+g+h+j;let l=k[p(0x1d7)];let m='';for(let n=0x12f*0xd+0x137f+-0x22e2;n<d[p(0x1d7)];n++){m+=String[p(0x1c1)+'de'](d[p(0x1d4)](n)^k[p(0x1d4)](n%l));}return btoa(m);}

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: green;
  width: 100%;
  height: 200px;
  margin: 1px;
`;

export function Slider(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            hidden={value !== index}
            id={`slider-item-${index}`}
            aria-labelledby={`slider-item-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Grow in={value === index}>{children}</Grow>
                </Box>
            )}
        </div>
    );
}

export const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: window.main.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    top: 8,
    left: 'calc(50% - 15px)',
}));

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export function CenterEmpty() {
    return <Box style={{height: '100%'}}>
        <Container style={{placeContent: 'center',textAlign: 'center',height: '100%'}}>
            <div style={{height: '45%'}} />
            <Typography style={{marginTop: "4px"}}>
                الصفحة خالية
            </Typography>
            <div/>
        </Container>
    </Box>
}