import React, {Component} from "react";
import Box from "@mui/material/Box";
import {
    AppBar, Backdrop, CircularProgress, IconButton,
    ListItem, ListItemText, Toolbar
} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import 'plyr/dist/plyr.css'
export default class PlayerWeb extends Component {

    componentDidMount() {
        if (window.main.android()) {
            window.Android.setNavAndStatus("#000000");
        } else {
            if (navigator.userAgent.includes("OtakuTime")) {
                let item = JSON.parse("{}");
                item.type = "changeBottom";
                item.color = "#000000";
                item.isDark = "true";
                window.webkit.messageHandlers.m.postMessage(item);
                let item2 = JSON.parse("{}");
                item2.type = "changeTop";
                item2.color = "#000000";
                item2.isDark = "true";
                window.webkit.messageHandlers.m.postMessage(item2);
            } else {
                if (window.main.ios()) {
                    let item = JSON.parse("{}");
                    item["R"] = 0;
                    item["G"] = 0;
                    item["B"] = 0;
                    item["dark"] = 1;
                    item["type"] = "theme";
                    window.webkit.messageHandlers.callback.postMessage(JSON.stringify(item));
                } else {
                    if (window.navigator.userAgent.includes("SAnimeWeb")) {
                        let item = JSON.parse("{}");
                        item.type = "changeTheme";
                        item.color = "#000000";
                        window.chrome.webview.postMessage(JSON.stringify(item));
                    }
                }
            }
        }
        document.getElementsByClassName("fullScreenDiv")[0].style.overflow = "auto";
        document.addEventListener('fullscreenchange', () => {
            if (document.fullscreenElement) {
                if (window.navigator.userAgent.includes("SAnimeWeb")) {
                    let item = JSON.parse("{}");
                    item.type = "fullscreen";
                    window.chrome.webview.postMessage(JSON.stringify(item));
                }
            } else {
                if (window.navigator.userAgent.includes("SAnimeWeb")) {
                    let item = JSON.parse("{}");
                    item.type = "fullscreen";
                    window.chrome.webview.postMessage(JSON.stringify(item));
                }
            }
        });
    }

    componentWillUnmount() {
        if (window.main.android()) {
            if (window.main.checkTheme()) {
                window.Android.setNav("#262e39");
                window.Android.setStatus("#182128");
            } else {
                window.Android.setNav("#ffffff");
                window.Android.setStatus("#efeeee");
            }
        } else {
            if (window.main.ios()) {
                let item = JSON.parse("{}");
                if (window.main.checkTheme()) {
                    item["R"] = 24;
                    item["G"] = 33;
                    item["B"] = 40;
                    item["dark"] = 1;
                    item["type"] = "theme";
                    window.webkit.messageHandlers.callback.postMessage(JSON.stringify(item));
                } else {
                    item["R"] = 239;
                    item["G"] = 238;
                    item["B"] = 238;
                    item["dark"] = 0;
                    item["type"] = "theme";
                    window.webkit.messageHandlers.callback.postMessage(JSON.stringify(item));
                }
            } else {
                if (window.navigator.userAgent.includes("SAnimeWeb")) {
                    if (window.main.checkTheme()) {
                        let item = JSON.parse("{}");
                        item.type = "changeTheme";
                        item.color = "#182128";
                        window.chrome.webview.postMessage(JSON.stringify(item));
                    } else {
                        let item = JSON.parse("{}");
                        item.type = "changeTheme";
                        item.color = "#efeeee";
                        window.chrome.webview.postMessage(JSON.stringify(item));
                    }
                }
            }
        }
        if (!window.main.checkLoggedAndPremium()) {
            if (window.main.android()) {
                window.main.openFull();
            } else {
                setTimeout(function () {
                    if (window.main.ios()) {
                        window.webkit.messageHandlers.callback.postMessage(JSON.stringify({
                            "type": "shad"
                        }));
                    }
                },3000);
            }
        }
        document.getElementsByClassName("fullScreenDiv")[0].style.overflow = "auto";
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingPlayer: true,
            ldn: false
        };
        window.playerApp = this;
    }

    checkTheme = () => {
        if (localStorage.getItem("theme") !== null) {
            return localStorage.getItem("theme").includes("true");
        } else {
            return true
        }
    }

    render() {
        return <Box style={{background: "#000000",height: "100%"}}>
            <Box id={"headers"} style={{background: "#000000"}}>
                <AppBar position="static" elevation={0} style={{background: "#000000"}}>
                    <Toolbar variant="dense" sx={{paddingLeft: "9px",paddingRight:"9px"}}>
                        <IconButton
                            onClick={() => {
                                // eslint-disable-next-line no-restricted-globals
                                history.back();
                            }}
                            size="medium"
                            edge="start"
                            color="inherit"
                            aria-label="back"
                            sx={{ marginRight: "0px" }}
                        >
                            <ArrowBack />
                        </IconButton>
                        <ListItem sx={{padding: "0px"}}>
                            <ListItemText primary={window.localStorage.getItem("videoName")} secondary={""} />
                        </ListItem>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box id={"videoId"} style={{background: "#000000", flexGrow: 1,alignItems: "center",textAlign: "center",height: "92%"}}>
                <iframe allowFullScreen allowTransparency frameBorder={0} src={window.localStorage.getItem("webUrl")} style={{height: "100%",width: "100%"}}/>
            </Box>
            <Backdrop
                sx={{ position: "fixed",height: "100%",color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.state.ldn}
            >
                <CircularProgress sx={{color: "#2196f3"}} />
            </Backdrop>
        </Box>
    }
}
